// global colors
$green: #2D9F98;
$yellow: #DFB51F;
$light-gray: #D9D4D3;
$dark-gray: #353D46;

$desktopMaxWidth: 1400px;

body{
    background-color: #efefef;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1rem;
    
}

.mobile body{
    font-size:8rem;
}

a{
    color: black;
}


h1{
    font-family: "Libre+Baskerville";
    font-size:2rem;
    font-weight: 600;
}
h2{
    font-size:1.5rem;
    font-weight: 500;
}
h3{
    font-size:1.2rem;
    font-weight: 400;
}
.mobile h1, .mobile h2{
    font-size:1.2rem;
}

.center{
    width: 100%;
    display: flex;
    justify-content: center;
}
.pointer{
    cursor:pointer;
}
section{
    margin:24px 0px;
}
.container{
    background-color: white;
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    max-width: $desktopMaxWidth;
}
.desktop.container {
    padding:0px 0px 80px 0px;
}

.endQuestion{
    padding:16px 0px;
    text-align: center;
    font-weight: 600;
}

.endQuestion{
    padding: 4px 0px;
}

.youtube-container {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
    border: 2px solid $dark-gray;
}
.youtube-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.no-decoration{
    text-decoration: none;
}
/******
** tailwind style 
********/
.pb-2{
    padding-bottom: 2rem;
}
.pb-4{
    padding-bottom: 4rem;
}

.pt-4{
    padding-top: 4rem;
}