@import '../pages/pages.scss';

.projectSlider{
    .imageRow{
        .serviceContainer{
            display: flex;
            justify-content: space-between;
            width: auto;
            margin-bottom: 32px;
            cursor: pointer;
            .title{
                position: absolute;
                top: 71%;
                width: 100%;
                display: flex;
                flex-direction: row;
                text-align: end;
                justify-content: flex-end;
                align-items: bottom;
                font-size:2rem;
                font-family: poppins;
                font-weight: 700;
                text-transform: uppercase;
                span{
                    background-color: #fff;
                    border: 1px solid $dark-gray;
                    padding: 0px 1rem;
                }
            }

            .imgOverlay{
                width: 100%;
                display: none;
                position: absolute;
                z-index: 10;
                font-size: 1.2rem;
                transition: clip-path 5s ease;
                clip-path: polygon(13% 0, 100% 0, 100% 0, 100% 0, 100% 33%, 76% 100%, 0 100%, 0 30%);
                width: 100%;
                strong{
                    font-size:2rem;
                }
            }
        }
        
    }
    .imageContainer{
        width: 100%;
        height: 350px;
        background-color: $dark-gray;
        display:flex;
        justify-content: center; 
    }

    .imageContainer img{
            margin-top:1px;
            height: 348px;
            width: calc(100% - 2px);
            object-fit: cover;
            object-position: center;
            opacity: 1;
           transition: transform 1s ease;
    }
    .imageContainer, .imageContainer img{
        clip-path: polygon(13% 0, 100% 0, 100% 0, 100% 0, 100% 33%, 76% 100%, 0 100%, 0 30%);
        transition: clip-path .5s ease;
        background-color: black;
    }

    .serviceContainer:hover .imageContainer img, .serviceContainer:hover .imageContainer, .imgOverlay {
        clip-path: polygon(0 0, 100% 0, 100% 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
    }
    .serviceContainer:hover{
        .imgOverlay{

            transition: transform 5s ease;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height:100%;
        }

        .imgOverlay:hover{
            transition: clip-path .5s ease;
            clip-path: polygon(0 0, 100% 0, 100% 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
        }
      
        .title{
            display:none;
        }
    }

}

.mobile .projectSlider{
    .imageRow{
        .serviceContainer{
            .title{
                font-size:1.4rem;
            }

            .imgOverlay{
                font-size: .85rem;
                strong{
                    display: none;
                }
            }
        }
    }
}

.serviceContainer {
    position: relative;
    overflow: hidden;
}

.serviceContainer img {
    width: auto;
    height:250px;
    display: block;
}

 .imgOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    padding: 10px 20px;
    box-sizing: border-box;
    width: 100%;
    font-size: 1rem;
}


.imgOverlay {
    display: none;
}

.serviceContainer:hover .title {
    display: none;
}

.serviceContainer:hover .imgOverlay {
    display: block;
}

.serviceContainer:hover .imageContainer img{
    opacity: .23;
}