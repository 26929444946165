@import '../../styles/global';

.footer {
    position: fixed;
    height: 60px;
    background-color:$dark-gray;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
    z-index: 499;
    display: flex;
    justify-content: space-around;
    div{
        display: flex;
        align-items: center;
        width: $desktopMaxWidth;
        justify-content: space-between;
    }
    span, span a{  
        padding: 8px 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: white;
        text-decoration: none; 
    }
    
    img{
        height:30px;
        padding-right: 4px;
    }
}