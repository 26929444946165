@import '../../styles/global';

.desktop .navBar{
    padding: 4px 24px;
    max-width: calc($desktopMaxWidth - 48px);
    display:flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 500;
    height: 80px;
    width: calc(100% - 48px);
    background: white;
    border-bottom: 1px solid #efefef;
    
    div {
        text-align: center;
        cursor: pointer;
    }
    img {
        height: 85px;
        margin-left: -40px;
    }

    a{
        text-decoration: none;
        color: black;
        padding:2px 8px;
        -webkit-user-select: none;
        user-select: none;
    }
}

.mobile .navBar{
    display: none;
}

.desktop .mobileNavBar{
    display: none;;
}

.desktop .logo img{
    transition: transform 0.5s ease;
}

.desktop .logo img:hover{
    transform: scale(1.3);
}

.mobileNavBar{
    position: fixed;
    z-index: 100;
    background-color: $dark-gray;
    width:100%;
    background-color: black;
    color: white;
    overflow: hidden;
   
    transition: height 1s ease-in-out;
    height: 50px;

    .menuIcon{
        cursor: pointer;
        padding-right: 16px;
    }

    .header{
        height:50px;
        width: 100%;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
  
    .linkedItems{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top:48px;
        height: 40vh;

        div{
            display: flex;
            justify-content: center;
            width: 100%;
            
        }
        a{
            color: $yellow;
            text-decoration: none;
            font-weight: 600;
            ;
        }
    }
   
}

.mobileNavBar.active{
    display: block;
    height: 80vh;
}

.linkHover{
    a:hover{
        color: $yellow;
        background-color: $dark-gray;
    }
}

.desktop .selected {
    a{color: $yellow;}
    background-color: $dark-gray;
}

