@import './global';
@media only screen and (max-width: 600px) {

    .desktop{
        display: none;
    }
  }

@media only screen and (min-width: 601px) {

    .mobile {
        display: none;
    }
}
@media only screen and (min-width: 1401px) {
    body{
        display:flex;
        justify-content: center;
    }
 
  
}

