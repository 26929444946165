@import '../../styles/global';

.pageContainer{
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  
    ::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
    width: 100vw;
    max-width: 1400px;
   .headerImg img{
    width: 100%;
    height:auto;
    max-height: 420px;
    object-fit: cover;
   }
}

.positionTop img{
    object-position: top;
}

.desktop .pageContainer{
    padding-top:80px;
}

.mobile .pageContainer{
    padding:50px 0px;

}

.pageContent{
    padding:0px 40px;
}


.processContainer{
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    align-items: top;
    grid-template-rows: masonry;
}
.mobile .processContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.processCard{
    width: 100%;
    max-width: 450px;
    color: #fff;
    background-color: $green;
    padding: 0px 0px;
    margin:0 auto;
    &:nth-child(even){
        background: $dark-gray;
      }
      
    .header{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        .counter{
            font-size:16px;
            text-transform: uppercase;
            font-weight: 600;
            padding:8px 24px;
            border-radius: 0 0 40% 0;
            background-color: rgba(255,255,255,.2);
            position: relative;
            top:-10px;
            // left:-12px;

            span{
                font-family: 'Libre+Baskerville';
                font-size: 64px;
                font-style: italic;
            }

            img{
                position: relative;
                padding-right: 4px;
                top: -4px;
            }
        }

    }
    .title{
        font-size: 24px;
        text-align: center;
    }
    .text{
        padding: 0px 48px 24px 48px;
    }
}

.mobile .processCard{
    flex-direction: column;
}


.capabilitiesCard{
    display: grid;
    grid-template-columns: auto auto auto;
    text-align: center;

    .title{
        font-weight: 600;
        text-transform: uppercase;
        color: $green;
        padding: 24px 0px 8px;
    }
    .skill{
        text-transform: capitalize;
        
    }
}
.mobile .capabilitiesCard{
    display: flex;
    flex-direction: column;
}

.emphasizedLarge{
    font-family: 'Libre+Baskerville';
    font-size: 48px;
    font-style: italic;
}

.hugeCallout{
    @media only screen and (min-width: 2001px) {
        font-size: 3rem;
    }
    @media only screen and (min-width: 1401px) {
        font-size: 2rem;
    }
    @media only screen and (min-width: 601px) and (max-width: 1400px){
        font-size: 1.8rem;
    }
    @media only screen and (max-width: 600px){
        font-size: 1.3rem;
    }

    font-weight: 700;
}