@import '../../styles/global';

.caseStudyContainer{
    max-width: 800px;
    
    padding-top: 40px;
    .desktop{
        padding: 16px 48px;
        min-width: 450px;
    }  

    h1{
        text-align: center;
    }
   
    .content{
        display: grid;
        grid-template-columns: 500px auto;
        grid-gap: 50px;
    }
    
    img{
        width: 100%;
        height: auto;
        max-height: 70vh;
        object-fit:contain;
    }
    .buttonHolder{
        display: flex;
        justify-content: center;
        padding:48px 0px;
    }
    .carousel{
        display: flex;
        justify-content: center;
        background-color: black;
        width: 100%;
    }

    .techStack{
        background-color: $yellow;
        border: 1px dashed black;
        padding: 24px;
    }

    .techStack ul{
        -moz-column-count: 4;
        -moz-column-gap: 20px;
        -webkit-column-count: 4;
        -webkit-column-gap: 20px;
        column-count: 4;
        column-gap: 20px;
    }

    .tagline{
        font-family: "Libre+Baskerville";
        font-size: 2rem;
        font-weight: 400;
        font-style: italic;
        text-align: center;
        padding: 36px 0px;
    }
    .mobile .tagline{
        font-size: 1.2rem;
    }
}

// SASS doesn't like super nested classes, so need to pull these out 

.mobile .caseStudyContainer img{
    width: 85%;
    height:auto;
    object-fit: contain;
}

.mobile .caseStudyContainer  .content{
    display:flex;
    flex-direction: column;
    grid-gap: 16px;

    .statement{
        order:2;
    }
    .caseImage{
        display: flex;
        justify-content: center;
        order:1;
    }
}

.desktop h2{
    padding-top:48px;
}
.mobile h2{
    padding-top: 16px;
}
.mobile .tagline{
    padding:16px 0px;
}

.challenge{
    padding: 24px 0px;
    .title{
        font-family: 'Libre+Baskerville';
        font-size: 24px;
        font-style: italic;
    }
    .description{
        
    }
}