@import '../../styles/global';

.textButton{
    cursor: pointer;
    span{
    background-color: $dark-gray;
    color: $yellow;
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: 400;
    text-decoration: none;
    }

    span:hover{
        font-weight: 600;
    }
}