@import "../../styles/global.scss";
.overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    top: 0;
    background-color: rgba(0, 0, 0, .75);
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .content{
        background-color: white;
        padding: 24px;
        border-radius: 4px;
        border:2px solid #000;
        max-height: 90%;
        overflow-y: scroll;
    }

    .closeButton{
        color: white;
        font-size:3rem;
        font-weight: 700;
        position:absolute;
        top:10px;
        right:40px;
        -webkit-text-stroke: 1px black;
        cursor:pointer;
    }
    .closeButton:hover{
        color: $yellow;
    }
}

    .mobile .overlay .closeButton{
        top:0px;
        right: 0px;
        padding:0px 16px;
        font-size:2rem;
        background-color: black;
    }